/** css reset */
html,
body,
div,
form,
fieldset,
legend,
label {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  background-color: #eeeeee;
  font-family: sans-serif;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.app-wrap {
  height: 100vh;
  margin: 0px auto;
  max-width: 420px;
  background-color: #fff;
}

.content-wrap {
  height: 80vh;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.tbpad {
  padding: 5px 0 !important;
}
.MuiTableRow-root.bold {
  border-bottom: 2px solid #000;
}
.MuiDialogContent-root.MuiDialogContent-dividers {
  padding: 0;
}
.chip-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  width: 285px;
}
.MuiChip-label {
  padding-left: 0 !important;
}
@media (max-width: 480px) {
  .nomob {
    display: none !important;
  }
}
